(function () {
    'use strict';

    angular.module('informaApp')
        .factory('AdminUserResolver', AdminUserResolver);

    function AdminUserResolver(AdminUserService) {
        return () => {
            return Promise.all([
                AdminUserService.getUsers({page: 0, limit: 25}),
                AdminUserService.getUserCount(),
                AdminUserService.getCompanies()
            ]).then(mapResult);
        }
    }

    function mapResult([users, userCount, userCompanies]) {
        return {
            users,
            userCount,
            userCompanies
        };
    }
})();
